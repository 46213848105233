import api from '@/services/api';
import AppError from '@/utils/appError';

const resource = 'leads';

/* eslint-disable */
const lista = async ({ status = {}, filtroEstatico = {}, plantaoAtivo = false, leadMailing = false }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.post(`${resource}/lista/corretor`, {
      transferidos: true,
      status,
      filtroEstatico,
      plantaoAtivo,
      leadMailing,
    }, config);
    const leadsTratados = data && data.data && data.data.leadsValidados ? data.data.leadsValidados.filter((lead) => status.includes(lead.status)) : [];
    const leadsComErros = data && data.data && data.data.leadsComErros ? data.data.leadsComErros : [];
    const listaLeads = {
      leads: leadsTratados,
      erros: leadsComErros,
    };
    return listaLeads;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};
/* eslint-enable */

const getLead = async ({ codigo, idLead }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/${codigo}/consulta${idLead ? '?idLead='.concat(idLead) : ''}`, config);
    return data && data.data ? data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getTratamentos = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.post(`${resource}/lista/corretor/${cpf}`, {}, config);
    let list = data && data.data ? data.data.tratamentos : [];
    list = list.map((e) => e.tratamento.descricao);
    return list.sort();
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getAvaliacaoMotivos = async (avaliacao) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };

    const { data } = await api.get(`${resource}/avaliacao/motivo/${avaliacao}`, config);

    return (data && data.data) || [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const alterar = async ({
  codigo, body,
}) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.post(`${resource}/${codigo}/tratamento`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const iniciarTratamento = async ({ codigo }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.put(`${resource}/${codigo}/iniciar-tratamento`, {}, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const avaliar = async ({
  codigo, nota, obs, motivo, justificativa,
}) => {
  try {
    const body = {
      nota,
      obs,
      motivo,
      justificativa,
    };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.put(`${resource}/${codigo}/avaliacao`, body, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const visualizar = async ({ idLead }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.put(`${resource}/${idLead}/visualizou`, {}, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const listaAlertasTratamento = async ({ cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/listaAlertasTratamento/${cpf}`, config);
    return data && data.data ? data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const visualizouAlertaTratamento = async ({ codigo, cpf }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/visualizouAlertaTratamento/${cpf}/${codigo}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const verificacaoInicialLeads = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/verificacaoInicial`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const updateHistoryTreatment = async (codigoLead, body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.post(`${resource}/${codigoLead}/historicoLead`, body, config);
    return data && data.data ? data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const searchHistory = async (codigoLead) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/${codigoLead}/lista/historicoLead`, config);
    return data && data.data ? data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const buscarTratamentos = async (tipoCorretor) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const routeWithQuery = `/tratamento/listaTratamentoFiltrados`;
    const body = {
      tipoCorretor,
    };

    const { data } = await api.post(`${resource}${routeWithQuery}`, body, config);
    return data && data.data ? data.data : null;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const listarLeadsVinculoProposta = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/vinculo-proposta`, config);
    return (data && data.data) || [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getMatchConversao = async (guidProposta) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/match-conversao/${guidProposta}`, config);
    return (data && data.data) || {};
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const vincularLeadAProposta = async (idLead, guidProposta) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const body = { idLead, guidProposta };
    await api.post(`${resource}/vinculo-proposta`, body, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const setContatoRealizado = async ({ codigo, contato }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    await api.post(`${resource}/contatoRealizado`, { codigo, contato }, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getPropostasAssociacao = async () => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.get(`${resource}/propostas/match-conversao-lead/pendentes`, config);
    return (data && data.data) || [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const vincularPropostaToLead = async (idLead, propostas) => {
  try {
    const body = { propostas };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    await api.post(`${resource}/match-conversao-lead/${idLead}`, body, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getListaAgendamentos = async (cpfCorretor, body) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    const { data } = await api.post(`${resource}/listaAgendamentos/corretor/${cpfCorretor}`, body, config);
    return (data && data.data) || [];
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const vincularPropostaManualToLead = async (idLead, nrPropostaManual, justificativaPropostaManual) => {
  try {
    const body = { nrPropostaManual, justificativaPropostaManual };
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_LEAD_API_KEY } };
    await api.post(`${resource}/vinculo-proposta-manual/${idLead}`, body, config);
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  lista,
  getTratamentos,
  alterar,
  getLead,
  iniciarTratamento,
  getListaAgendamentos,
  avaliar,
  getAvaliacaoMotivos,
  visualizar,
  listaAlertasTratamento,
  visualizouAlertaTratamento,
  verificacaoInicialLeads,
  updateHistoryTreatment,
  searchHistory,
  buscarTratamentos,
  listarLeadsVinculoProposta,
  getMatchConversao,
  vincularLeadAProposta,
  setContatoRealizado,
  getPropostasAssociacao,
  vincularPropostaToLead,
  vincularPropostaManualToLead,
};
